import React from "react";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { Button, buttonVariants } from "../../../Global/UI/Button";
import { TrashIcon } from "@radix-ui/react-icons";
import { BASE_URL } from "../../../../Screens/Settings/DASHBOARD_SETTINGS";
import { toast } from 'react-toastify';
import axios from 'axios';
const ViewJourneyDetails = ({ onChange, data }) => {

    const [journeyDetails, setJourneyDetails] = React.useState(data);
    const [locations, setLocations] = React.useState()


    React.useEffect(() => {
        getLocations()
    },[])

    const handleChange = (e, index) => {
        const { name, value } = e.target;
        const updatedJourneys = [...journeyDetails.journeys];
        updatedJourneys[index] = {
            ...updatedJourneys[index],
            [name]: value,
        };
        setJourneyDetails(prevState => ({
            ...prevState,
            journeys: updatedJourneys,
        }));
        onChange({
            ...journeyDetails,
            journeys: updatedJourneys,
        });
    };

    const handleSelectPickup = async (address, index) => {
        const results = await geocodeByAddress(address);
        const latLng = await getLatLng(results[0]);
        const updatedJourneys = [...journeyDetails.journeys];
        updatedJourneys[index] = {
            ...updatedJourneys[index],
            otherPickupLocation: address,
        };
        setJourneyDetails(prevState => ({
            ...prevState,
            journeys: updatedJourneys,
        }));
        onChange({
            ...journeyDetails,
            journeys: updatedJourneys,
        });
    };

    const handleSelectDropOff = async (address, index) => {
        const results = await geocodeByAddress(address);
        const latLng = await getLatLng(results[0]);
        const updatedJourneys = [...journeyDetails.journeys];
        updatedJourneys[index] = {
            ...updatedJourneys[index],
            otherDropOffLocation: address,
        };
        setJourneyDetails(prevState => ({
            ...prevState,
            journeys: updatedJourneys,
        }));
        onChange({
            ...journeyDetails,
            journeys: updatedJourneys,
        });
    };

    const addTrip = () => {
        setJourneyDetails({
            ...journeyDetails,
            journeys: [
                ...journeyDetails.journeys,
                {
                    pickupLocation: '',
                    otherPickupLocation: '',
                    pickUpLocationArea: '',
                    dateTimePickup: '',
                    timePickup: '',
                    dropOffLocation: '',
                    otherDropOffLocation: '',
                    dropOffLocationArea: '',
                    distance: ''
                }
            ]
        });
    };

    const removeTrip = (index) => {
        const updatedJourneys = journeyDetails.journeys.filter((trip, i) => i !== index);
        setJourneyDetails({
            ...journeyDetails,
            journeys: updatedJourneys
        });
        onChange({
            ...journeyDetails,
            journeys: updatedJourneys
        });
    };

    const createReturnTrip = (index) => {
        const currentTrip = journeyDetails.journeys[index];
        const returnTrip = {
            pickupLocation: currentTrip.dropOffLocation,
            otherPickupLocation: currentTrip.otherDropOffLocation,
            pickUpLocationArea: currentTrip.dropOffLocationArea,
            dropOffLocation: currentTrip.pickupLocation,
            otherDropOffLocation: currentTrip.otherPickupLocation,
            dropOffLocationArea: currentTrip.pickUpLocationArea,
            dateTimePickup: currentTrip.dateTimePickup,
            distance: currentTrip.distance
        };

        const updatedJourneys = [
            ...journeyDetails.journeys.slice(0, index + 1),
            returnTrip,
            ...journeyDetails.journeys.slice(index + 1)
        ];

        setJourneyDetails({
            ...journeyDetails,
            journeys: updatedJourneys
        });

        onChange({
            ...journeyDetails,
            journeys: updatedJourneys
        });
    };

    const getLocations = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/api/settings/repatriation/locations`)
            if (response.data.success === true) {
                setLocations(response.data.data);
            } else {
                toast.error('Locations failed to load!');
            }
        } catch (error) {
            toast.error(error.msg);
        }
    }


    return (
        <div>
            <div className="flex flex-row items-center justify-between">
                <p className='font-bold text-2xl mb-4'>Journey Details</p>
                <Button
                    onClick={() => addTrip()}
                    variant="default"
                    size="default"
                    className={buttonVariants({ variant: "default", size: "default" })}
                >
                    Add Trip
                </Button>
            </div>
            {journeyDetails.journeys.map((trip, index) => (
                <div className="border p-4 rounded my-4">
                    <div className="flex flex-row items-center justify-between">
                        <p className='font-bold text-xl mb-2'>Trip #{index + 1}</p>
                        <div className="flex flex-row items-center gap-4">
                            <Button
                                onClick={() => createReturnTrip(index)}
                                className=''
                            >
                                Create Return
                            </Button>
                            <Button
                                onClick={() => removeTrip(index)}
                                className=''
                            >
                                <TrashIcon />
                            </Button>
                        </div>
                    </div>
                    <div className="grid gap-4 grid-cols-1 md:grid-cols-3 mb-4">
                        <div>
                            <label htmlFor="pickupLocation" className="block font-bold mb-2">Pickup Address:</label>
                            <select
                                value={trip.pickupLocation}
                                onChange={(e) => handleChange(e, index)}
                                id="pickupLocation"
                                name="pickupLocation"
                                className="w-full p-2 border bg-white border-gray-300 rounded focus:ring-2 focus:ring-inset focus:ring-teal-400"
                            >
                                <option value=""></option>
                                {locations ? locations.map((item, index) => (
                                    <option key={index} value={item.address}>{item.name}</option>
                                )) : ''}
                                <option value="Other">Other</option>
                            </select>
                        </div>
                        {trip.pickupLocation !== 'Other' && (
                            <div>
                                <label htmlFor="pickUpLocationArea" className="block font-bold mb-2">Pickup Location Area:</label>
                                <input
                                    value={trip.pickUpLocationArea}
                                    onChange={(e) => handleChange(e, index)}
                                    type="text"
                                    id="pickUpLocationArea"
                                    name="pickUpLocationArea"
                                    className="w-full p-2 border bg-white border-gray-300 rounded focus:ring-2 focus:ring-inset focus:ring-teal-400"
                                />
                            </div>
                        )}
                        {trip.pickupLocation === 'Other' && (
                            <div>
                                <label htmlFor="otherPickupLocation" className="block font-bold mb-2">Please enter the pickup address:</label>
                                <PlacesAutocomplete
                                 debounce={400}
                                    value={trip.otherPickupLocation}
                                    onChange={(address) => handleChange({ target: { name: "otherPickupLocation", value: address } }, index)}
                                    onSelect={(address) => handleSelectPickup(address, index)}
                                >
                                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                        <div>
                                            <input
                                                {...getInputProps({
                                                    placeholder: 'Type your pickup address...',
                                                    className: 'w-full p-2 border bg-white border-gray-300 rounded focus:ring-2 focus:ring-inset focus:ring-teal-400',
                                                })}
                                            />
                                            <div>
                                                {loading && <div>Loading...</div>}
                                                {suggestions.map(suggestion => {
                                                    const style = {
                                                        backgroundColor: suggestion.active ? '#41b6e6' : '#fff',
                                                    };
                                                    return (
                                                        <div {...getSuggestionItemProps(suggestion, { style })} key={suggestion.placeId}>
                                                            {suggestion.description}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    )}
                                </PlacesAutocomplete>
                            </div>
                        )}

                        <div>
                            <label htmlFor="dateTimePickup" className="block font-bold mb-2">Date of Pickup:</label>
                            <input
                                value={trip.dateTimePickup}
                                onChange={(e) => handleChange(e, index)}
                                type="date"
                                id="dateTimePickup"
                                name="dateTimePickup"
                                className="w-full p-2 border border-gray-300 rounded focus:ring-2 focus:ring-inset focus:ring-teal-400"
                            />
                        </div>

                        <div>
                            <label htmlFor="timePickup" className="block font-bold mb-2">Time of Pickup:</label>
                            <input
                                value={trip.timePickup}
                                onChange={(e) => handleChange(e, index)}
                                type="time"
                                id="timePickup"
                                name="timePickup"
                                className="w-full p-2 border border-gray-300 rounded focus:ring-2 focus:ring-inset focus:ring-teal-400"
                            />
                        </div>

                        <div>
                            <label htmlFor="dropOffLocation" className="block font-bold mb-2">Drop Off Address:</label>
                            <select
                                value={trip.dropOffLocation}
                                onChange={(e) => handleChange(e, index)}
                                id="dropOffLocation"
                                name="dropOffLocation"
                                className="w-full p-2 border bg-white border-gray-300 rounded focus:ring-2 focus:ring-inset focus:ring-teal-400"
                            >
                                <option value=""></option>
                                <option value="4 Newbridge Rd, Coleraine BT52 1HS">Causeway Hospital</option>
                                <option value="Bush Rd, Antrim BT41 2RL">Antrim Hospital</option>
                                <option value="Glenshane Rd, Londonderry BT47 6SB">Altnagelvin Hospital</option>
                                <option value="South West Acute Hospital, Irvinestown Road, Enniskillen, UK">South West Acute</option>
                                <option value="274 Grosvenor Rd, Belfast BT12 6BA">Royal Hospital</option>
                                <option value="Upper Newtownards Rd, Dundonald, Belfast BT16 1RH">Ulster Hospital</option>
                                <option value="Lisburn Rd, Belfast BT9 7AB">Belfast City Hospital</option>
                                <option value="Mater Hospital, Crumlin Road, Belfast, UK">Mater Hospital</option>
                                <option value="Other">Other</option>
                            </select>
                        </div>
                        {trip.dropOffLocation === 'Other' && (
                            <div>
                                <label htmlFor="otherDropOffLocation" className="block font-bold mb-2">Please enter the drop-off address:</label>
                                <PlacesAutocomplete
                                 debounce={400}
                                    value={trip.otherDropOffLocation}
                                    onChange={(address) => handleChange({ target: { name: "otherDropOffLocation", value: address } }, index)}
                                    onSelect={(address) => handleSelectDropOff(address, index)}
                                >
                                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                        <div>
                                            <input
                                                {...getInputProps({
                                                    placeholder: 'Type your drop-off address...',
                                                    className: 'w-full p-2 border bg-white border-gray-300 rounded focus:ring-2 focus:ring-inset focus:ring-teal-400',
                                                })}
                                            />
                                            <div>
                                                {loading && <div>Loading...</div>}
                                                {suggestions.map(suggestion => {
                                                    const style = {
                                                        backgroundColor: suggestion.active ? '#41b6e6' : '#fff',
                                                    };
                                                    return (
                                                        <div {...getSuggestionItemProps(suggestion, { style })} key={suggestion.placeId}>
                                                            {suggestion.description}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    )}
                                </PlacesAutocomplete>
                            </div>
                        )}
                        {trip.dropOffLocation !== 'Other' && (
                            <div>
                                <label htmlFor="dropOffLocationArea" className="block font-bold mb-2">Drop-off Location Area:</label>
                                <input
                                    value={trip.dropOffLocationArea}
                                    onChange={(e) => handleChange(e, index)}
                                    type="text"
                                    id="dropOffLocationArea"
                                    name="dropOffLocationArea"
                                    className="w-full p-2 border bg-white border-gray-300 rounded focus:ring-2 focus:ring-inset focus:ring-teal-400"
                                />
                            </div>
                        )}
                    </div>
                </div>
            ))}
        </div>
    )
}

export default ViewJourneyDetails