import { Label } from "@radix-ui/react-label";
import axios from "axios";
import React, { useEffect, useState } from "react";
import PlacesAutocomplete from "react-places-autocomplete";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { toast } from "react-toastify";

import { Button } from "../../Components/Global/UI/Button";
import { Input } from "../../Components/Global/UI/Input";
import { Select, SelectContent, SelectItem, SelectTrigger } from "../../Components/Global/UI/Select";
import JobCard from "../../Components/Jobs/JobCard";
import StaffCard from "../../Components/Jobs/StaffCard";
import { BASE_URL } from "../Settings/DASHBOARD_SETTINGS";

const requiredFields = ["trust", "costCenter", "jobStartDate", "logonTime", "logoffTime"];
const trustData = [
  { name: "Northern", lot: 4 },
  { name: "Western", lot: 10 },
  { name: "Belfast", lot: 2 },
  { name: "South Eastern", lot: 6 },
];


const AddShiftJob = () => {
  const [formData, setFormData] = useState({
    jobDetails: {
      trust: "",
      costCenter: "",
      jobStartDate: "",
      price: "",
      callSign: "",
      lot: "",
      vehicle: "",
    },
    staffDetails: {
      staffList: [
        { id: 1, name: "", jobType: "ACA" },
        { id: 2, name: "", jobType: "ACA" },
      ],
    },
    shiftDetails: {
      logonTime: "",
      logoffTime: "",
    },
    jobs: {
      totalMiles: "",
      totalJobs: "",
      jobList: [
        {
          id: 1,
          startingAddress: "",
          endingAddress: "",
          miles: "",
          referenceNumber: "",
        },
      ],
    },
    breakDetails: {
      break1: {
        visible: false,
        time: "",
      },
      break2: {
        visible: false,
        time: "",
      },
    },
  });


  const [missingFields, setMissingFields] = useState([]);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [drivers, setDrivers] = useState([]);

  const handleAddShiftJob = async () => {
    const requiredData = {
      trust: formData.jobDetails.trust,
      costCenter: formData.jobDetails.costCenter,
      jobStartDate: formData.jobDetails.jobStartDate,
      logonTime: formData.shiftDetails.logonTime,
      logoffTime: formData.shiftDetails.logoffTime,
    };

    const missing = requiredFields.filter((field) => !requiredData[field]);

    if (missing.length > 0) {
      setMissingFields(missing);
      toast.error("Please fill in all required fields.");
      return;
    }

    setLoading(true);
    try {
      const response = await axios.post(`${BASE_URL}/api/nias/job/create-job`, { formData });

      if (response.data.success) {
        setLoading(false);
        toast.success("Successfully Created Job");
        history.push("/");
      }
    } catch (error) {
      setLoading(false);
      toast.error("An error occurred while creating the job.");
      console.error("Error:", error);
    }
  };

  React.useEffect(() => {
    const fetchDrivers = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/api/drivers/getDrivers`);
        if (response.data.success) {
          setDrivers(response.data.drivers);
        }
      } catch (error) {
        console.error("Error fetching drivers:", error.message);
        toast.error("Failed to fetch drivers");
      }
    };
    fetchDrivers();
  }, []);

  const handleVehicleSelect = (selectedVehicle) => {
    setFormData((prevState) => ({
      ...prevState,
      jobDetails: {
        ...prevState.jobDetails,
        vehicle: `${selectedVehicle.username}: ${selectedVehicle.registrationNumber}`,
      },
    }));
  };

  useEffect(() => {
    const totalJobs = formData.jobs.jobList.length;
    const totalMilage = formData.jobs.jobList.reduce(
      (acc, job) => acc + parseFloat(job.miles || 0),
      0
    );
    setFormData((prevState) => ({
      ...prevState,
      jobs: { ...prevState.jobs, totalJobs, totalMiles: totalMilage },
    }));
  }, [formData.jobs.jobList]);

  const handleInputChange = (e) => {
    const { name, value, type } = e.target;
    const updatePath = name.split(".");
    let updatedValue = value;

    if (type === "number") {
      updatedValue = parseFloat(value);
      if (isNaN(updatedValue)) updatedValue = 0;
    }

    setFormData((prevState) => {
      const newState = { ...prevState };
      let current = newState;
      updatePath.forEach((key, index) => {
        if (index === updatePath.length - 1) {
          current[key] = updatedValue;
        } else {
          current = current[key];
        }
      });
      return newState;
    });
  };

  const handleTrustSelect = (selectedTrust) => {
    const trust = trustData.find((t) => t.name === selectedTrust);
    if (trust) {
      setFormData((prevState) => ({
        ...prevState,
        jobDetails: {
          ...prevState.jobDetails,
          trust: selectedTrust,
          lot: trust.lot.toString(),
        },
      }));
      setMissingFields((prev) => prev.filter((field) => field !== "trust"));
    }
  };

  const handleCostCenterSelect = (selectedCostCenter) => {
    setFormData((prevState) => ({
      ...prevState,
      jobDetails: {
        ...prevState.jobDetails,
        costCenter: selectedCostCenter,
      },
    }));
  };

  const addStaffMember = () => {
    setFormData((prevState) => ({
      ...prevState,
      staffDetails: {
        ...prevState.staffDetails,
        staffList: [
          ...prevState.staffDetails.staffList,
          {
            id: prevState.staffDetails.staffList.length + 1,
            name: "",
            jobType: "ACA",
          },
        ],
      },
    }));
  };

  const handleStaffNameChange = (staffId, newName) => {
    setFormData((prevState) => ({
      ...prevState,
      staffDetails: {
        ...prevState.staffDetails,
        staffList: prevState.staffDetails.staffList.map((staff) =>
          staff.id === staffId ? { ...staff, name: newName } : staff
        ),
      },
    }));
  };

  const handleJobDataChange = (jobId, field, value) => {
    setFormData((prevState) => ({
      ...prevState,
      jobs: {
        ...prevState.jobs,
        jobList: prevState.jobs.jobList.map((job) =>
          job.id === jobId ? { ...job, [field]: value } : job
        ),
      },
    }));
  };

  const addJob = () => {
    const newJobId = formData.jobs.jobList.length + 1;
    setFormData((prevState) => ({
      ...prevState,
      jobs: {
        ...prevState.jobs,
        jobList: [
          ...prevState.jobs.jobList,
          { id: newJobId, startingAddress: "", endingAddress: "", miles: "" },
        ],
      },
    }));
  };

  const removeJob = (jobId) => {
    setFormData((prevState) => ({
      ...prevState,
      jobs: {
        ...prevState.jobs,
        jobList: prevState.jobs.jobList.filter((job) => job.id !== jobId),
      },
    }));
  };

  const handleJobTypeChange = (staffId, jobType) => {
    setFormData((prevState) => ({
      ...prevState,
      staffDetails: {
        ...prevState.staffDetails,
        staffList: prevState.staffDetails.staffList.map((staff) =>
          staff.id === staffId ? { ...staff, jobType } : staff
        ),
      },
    }));
  };

  const removeStaffMember = (staffId) => {
    setFormData((prevState) => ({
      ...prevState,
      staffDetails: {
        ...prevState.staffDetails,
        staffList: prevState.staffDetails.staffList.filter(
          (staff) => staff.id !== staffId
        ),
      },
    }));
  };

  const handleAddReturnJob = (returnJob) => {
    setFormData((prevState) => ({
      ...prevState,
      jobs: {
        ...prevState.jobs,
        jobList: [...prevState.jobs.jobList, returnJob],
      },
    }));
  };

  return (
    <div className="px-10 my-8">
      <div className="mb-4">
        <div className="flex flex-row items-center justify-between">
          <p className="text-lg font-semibold leading-none mb-2">Job Details</p>
          <Button onClick={() => handleAddShiftJob()}>Create Job</Button>
        </div>
        <div className="grid w-full grid-cols-2 gap-4 mb-4">
          <div className="flex flex-col gap-1.5">
            <Label htmlFor="price">Starting Price</Label>
            <Input
              type="text"
              id="price"
              name="price"
              value={formData.jobDetails.price}
              onChange={handleInputChange}
            />
          </div>
          <div className="flex flex-col gap-1.5">
            <Label htmlFor="logonTime">Trust</Label>
            <Select
              value={formData.jobDetails.trust}
              onValueChange={handleTrustSelect}
            >
              <SelectTrigger className={missingFields.includes("trust") ? "border-red-500" : ""}>
                {formData.jobDetails.trust || "Select Trust"}
              </SelectTrigger>
              <SelectContent>
                {trustData.map((trust) => (
                  <SelectItem key={trust.name} value={trust.name}>
                    {trust.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
          <div className="flex flex-col gap-1.5">
            <Label htmlFor="logonTime">Cost Center</Label>
            <Select
              value={formData.jobDetails.costCenter}
              onValueChange={handleCostCenterSelect}
            >
             <SelectTrigger className={missingFields.includes("costCenter") ? "border-red-500" : ""}>
                {formData.jobDetails.costCenter || "Select Cost Center"}
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="FCAE01">EAC</SelectItem>
                <SelectItem value="FCPE01">NEAC</SelectItem>
                <SelectItem value="Trust">Trust</SelectItem>
              </SelectContent>
            </Select>
          </div>
          <div className="flex flex-col gap-1.5">
            <Label htmlFor="jobStartDate">Job Start Date</Label>
            <Input
              type="date"
              id="jobStartDate"
              name="jobDetails.jobStartDate"
              value={formData.jobDetails.jobStartDate}
              onChange={handleInputChange}
              className={missingFields.includes("jobStartDate") ? "border-red-500" : ""}
            />
          </div>
          <div className="flex flex-col gap-1.5">
            <Label htmlFor="vehicle">Vehicle</Label>
            <Select
              value={formData.jobDetails.vehicle}
              onValueChange={handleVehicleSelect}
            >
              <SelectTrigger>
                {formData.jobDetails.vehicle
                  ? formData.jobDetails.vehicle
                  : "Select Vehicle"}
              </SelectTrigger>
              <SelectContent>
                {drivers.map((driver) => (
                  <SelectItem key={driver.username} value={driver}>
                    {driver.username}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
          <div className="flex flex-col gap-1.5">
            <Label htmlFor="callSign">Call Sign</Label>
            <Input
              type="text"
              id="callSign"
              name="jobDetails.callSign"
              value={formData.jobDetails.callSign}
              onChange={handleInputChange}
            />
          </div>
          <div className="flex flex-col gap-1.5">
            <Label htmlFor="lot">Lot Number</Label>
            <Input
              value={formData.jobDetails.lot}
              onChange={handleInputChange}
              type="text"
              id="lot"
              name="jobDetails.lot"
              placeholder="1"
            />
          </div>
        </div>
        <div className="mt-4">
          <div className="flex flex-row items-center mb-4 justify-between">
            <p className="text-lg font-semibold leading-none">Staff Details</p>
            <Button onClick={addStaffMember}>Add Staff</Button>
          </div>
          <div className="grid grid-cols-1 gap-4">
            {formData.staffDetails.staffList.map((staff) => (
              <div key={staff.id}>
                <StaffCard
                  staff={staff}
                  onStaffNameChange={handleStaffNameChange}
                  onRemoveStaff={() => removeStaffMember(staff.id)}
                  onJobTypeChange={(jobType) =>
                    handleJobTypeChange(staff.id, jobType)
                  }
                />
              </div>
            ))}
          </div>
        </div>
        <div className="mt-4">
          <p className="text-lg font-semibold leading-none mb-2">
            Shift Details
          </p>
          <div className="grid w-full grid-cols-2 gap-4">
            <div className="flex flex-col gap-1.5">
              <Label htmlFor="logonTime">Log on time</Label>
              <Input
                value={formData.shiftDetails.logonTime}
                onChange={handleInputChange}
                type="time"
                id="logonTime"
                name="shiftDetails.logonTime"
                className={missingFields.includes("logonTime") ? "border-red-500" : ""}
              />
            </div>
            <div className="flex flex-col gap-1.5">
              <Label htmlFor="logoffTime">Log off time</Label>
              <Input
                value={formData.shiftDetails.logoffTime}
                onChange={handleInputChange}
                type="time"
                id="logoffTime"
                name="shiftDetails.logoffTime"
                className={missingFields.includes("logoffTime") ? "border-red-500" : ""}

              />
            </div>
          </div>
        </div>
        <div className="mt-4">
          <div className="flex flex-row items-center mb-4 justify-between">
            <p className="text-lg font-semibold leading-none">Job Details</p>
          </div>
          <div className="grid grid-cols-1 gap-4">
            {formData.jobs.jobList.map((job) => (
              <div key={job.id}>
                <JobCard
                  job={job}
                  onJobDataChange={handleJobDataChange}
                  onRemoveJob={() => removeJob(job.id)}
                  onAddReturnJob={handleAddReturnJob}
                />
              </div>
            ))}
            <Button onClick={addJob}>Add Job</Button>
          </div>
        </div>
        <div className="grid w-full grid-cols-2 gap-4 my-4">
          <div className="flex flex-col gap-1.5">
            <Label htmlFor="vehicle">Total Miles</Label>
            <Input
              value={formData.jobs.totalMiles}
              onChange={handleInputChange}
              type="number"
              id="totalMiles"
              name="totalMiles"
              placeholder="1"
            />
          </div>

          <div className="flex flex-col gap-1.5">
            <Label htmlFor="vehicle">Total Jobs</Label>
            <Input
              value={formData.jobs.totalJobs}
              onChange={handleInputChange}
              type="number"
              id="totalJobs"
              name="totalJobs"
              placeholder="1"
            />
          </div>
        </div>
      </div>
      <div className="mt-4">
        <p className="text-lg font-semibold leading-none mb-2">Break Details</p>
        <p className="text-sm leading-none mb-2">{`These are 30 min breaks - Break 1 visible from 6-8 hrs - Break 2 visible from >8 hrs `}</p>
        <div className="grid w-full grid-cols-2 gap-4">
          {formData.breakDetails.break1.visible && (
            <div className="flex flex-col gap-1.5">
              <Label htmlFor="break1Time">Break 1 Time</Label>
              <Input
                type="time"
                id="break1Time"
                name="breakDetails.break1.time" // Note the use of dot notation for nested property
                value={formData.breakDetails.break1.time}
                onChange={handleInputChange}
              />
            </div>
          )}
          {formData.breakDetails.break2.visible && (
            <div className="flex flex-col gap-1.5">
              <Label htmlFor="break2Time">Break 2 Time</Label>
              <Input
                type="time"
                id="break2Time"
                name="breakDetails.break2.time" // Note the use of dot notation for nested property
                value={formData.breakDetails.break2.time}
                onChange={handleInputChange}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddShiftJob;