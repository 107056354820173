import React from 'react';

import { Badge } from '../Components/Global/UI/Badge';

const renderStatusBadge = (status) => {
  let badgeColor = '';
  let badgeText = '';
  let textColor = '';
  let hoverColor = '';

  switch (status) {
    case 1:
      badgeText = 'Assigned';
      textColor = 'text-yellow-800';
      badgeColor = 'bg-yellow-200';
      hoverColor = 'hover:bg-yellow-300';
      break;
    case 2:
      badgeText = 'Accepted';
      textColor = 'text-sky-900';
      badgeColor = 'bg-sky-200';
      hoverColor = 'hover:bg-sky-300';
      break;
    case 3:
      badgeText = 'Started';
      textColor = 'text-orange-800';
      badgeColor = 'bg-orange-200';
      hoverColor = 'hover:bg-orange-300';
      break;
    case 4:
      badgeText = 'On Scene';
      textColor = 'text-blue-800';
      badgeColor = 'bg-blue-200';
      hoverColor = 'hover:bg-blue-300';
      break;
    case 5:
      badgeText = 'Left Scene';
      textColor = 'text-indigo-800';
      badgeColor = 'bg-indigo-200';
      hoverColor = 'hover:bg-indigo-300';
      break;
    case 6:
      badgeText = 'Completed';
      textColor = 'text-teal-800';
      badgeColor = 'bg-teal-200';
      hoverColor = 'hover:bg-teal-300';
      break;
    case 7:
      badgeText = 'Cancelled';
      textColor = 'text-red-800';
      badgeColor = 'bg-red-200';
      hoverColor = 'hover:bg-red-300';
      break;
    case 55:
    case 65:
      badgeText = 'Ongoing';
      textColor = 'text-purple-800';
      badgeColor = 'bg-purple-200';
      hoverColor = 'hover:bg-purple-300';
      break;
    default:
      badgeText = 'Unknown';
      textColor = 'text-gray-800';
      badgeColor = 'bg-gray-200';
      hoverColor = 'hover:bg-gray-300';
  }

  return (
    <Badge className={`${badgeColor} ${textColor} ${hoverColor}`}>
      {badgeText}
    </Badge>
  );
};

export default renderStatusBadge;