import { Label } from "@radix-ui/react-label";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";
import { toast } from "react-toastify";

import { Badge } from "../Components/Global/UI/Badge";
import { Button, buttonVariants } from "../Components/Global/UI/Button";
import { Dialog, DialogClose, DialogContent, DialogDescription, DialogHeader, DialogTitle } from "../Components/Global/UI/Dialog";
import { Input } from "../Components/Global/UI/Input";
import { Select, SelectContent, SelectItem, SelectTrigger } from "../Components/Global/UI/Select";
import JobCard from "../Components/Jobs/JobCard";
import StaffCard from "../Components/Jobs/StaffCard";
import { useAuth } from "../Context/AuthProvider";
import { BASE_URL } from "./Settings/DASHBOARD_SETTINGS";

// Removed pricing properties; only trust name and lot are needed
const trustData = [
  { name: "Northern", lot: 4 },
  { name: "Western", lot: 10 },
  { name: "Belfast", lot: 2 },
  { name: "South Eastern", lot: 6 },
];

const ViewNIASJob = () => {
  const location = useLocation();
  const data = location.state.item;

  const initialJobStartDate =
    data.jobDetails && data.jobDetails.jobStartDate
      ? data.jobDetails.jobStartDate.split("T")[0]
      : "";
  const [formData, setFormData] = useState({
    jobDetails: {
      ...data.jobDetails,
      jobStartDate: initialJobStartDate,
    },
    staffDetails: data.staffDetails || {},
    shiftDetails: data.shiftDetails || {},
    jobs: data.jobs || {},
    breakDetails: data.breakDetails || {},
  });

  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [drivers, setDrivers] = useState(null);
  const [selectedDriverUsername, setSelectedDriverUsername] = useState("");
  const { authData } = useAuth();
  const [copiedJobs, setCopiedJobs] = useState([]);

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  useEffect(() => {
    const { logonTime, logoffTime } = formData.shiftDetails;
    let trust = formData.jobDetails.trust;
    let costCenter = formData.jobDetails.costCenter

    console.log("Trust From Time:", trust);
    console.log('Cost Center from Time: ' , costCenter)

    if (!logonTime || !logoffTime) return;

    const [logonHour, logonMinute] = logonTime.split(":").map(Number);
    const [logoffHour, logoffMinute] = logoffTime.split(":").map(Number);
    let totalMinutes =
      (logoffHour - logonHour) * 60 + (logoffMinute - logonMinute);

    if (logoffHour < logonHour) {
      totalMinutes += 24 * 60;
    }

    let break1Visible = false;
    let break2Visible = false;

    if (totalMinutes > 6 * 60 && totalMinutes <= 10.5 * 60) {
      break1Visible = true;
    } else if (totalMinutes > 10.5 * 60) {
      break1Visible = true;
      break2Visible = true;
    }

    let adjustedMinutes = totalMinutes;
    if (costCenter !== "Trust") {
      console.log("Checking Breaks...");
      if (break1Visible && !break2Visible) {
        adjustedMinutes -= 30;
      } else if (break2Visible) {
        adjustedMinutes -= 60;
      }
    }

    const totalHours = adjustedMinutes / 60;
    setFormData((prevState) => ({
      ...prevState,
      shiftDetails: {
        ...prevState.shiftDetails,
        totalHours: totalHours,
      },
      breakDetails: {
        break1: { visible: break1Visible },
        break2: { visible: break2Visible },
      },
    }));
  }, [formData.shiftDetails.logonTime, formData.shiftDetails.logoffTime]);

  const handleAssignJob = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/nias/job/assignJob/${selectedDriverUsername.toUpperCase()}/${
          data._id
        }`
      );
      if (response.data.success) {
        toast.success("Successfully Assigned Job");
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        toast.error(error.response.data.message);
        console.error("Item not found:", error.response.data.message);
      } else {
        toast.error("An unknown error has occurred");
        console.error("Error:", error.message);
      }
    }
  };

  const handleUpdateShiftJob = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${BASE_URL}/api/nias/job/${data._id}/update-nias-job`,
        { formData }
      );
      if (response.data.success) {
        setLoading(false);
        toast.success("Successfully Updated Job");
        history.push("/");
      }
    } catch (error) {
      setLoading(false);
      if (error.response && error.response.status === 404) {
        toast.error(error.response.data.message);
        console.error("Item not found:", error.response.data.message);
      } else {
        toast.error("An unknown error has occurred");
        console.error("Error:", error.message);
      }
    }
  };

  const handleDeleteShiftJob = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${BASE_URL}/api/nias/job/${data._id}/delete-nias-job`
      );
      if (response.data.success) {
        setLoading(false);
        toast.success(response.data.msg);
        history.push("/");
      }
    } catch (error) {
      setLoading(false);
      if (error.response && error.response.status === 404) {
        toast.error(error.response.data.message);
        console.error("Item not found:", error.response.data.message);
      } else {
        toast.error("An unknown error has occurred");
        console.error("Error:", error.message);
      }
    }
  };

  // Update job totals when jobList changes
  useEffect(() => {
    const totalJobs = formData.jobs.jobList.length;
    const totalMilage = formData.jobs.jobList.reduce(
      (acc, job) => acc + parseFloat(job.miles || 0),
      0
    );
    setFormData((prevState) => ({
      ...prevState,
      jobs: { ...prevState.jobs, totalJobs, totalMiles: totalMilage },
    }));
  }, [formData.jobs.jobList]);

  // Fetch drivers once
  useEffect(() => {
    const fetchDrivers = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/api/drivers/getDrivers`);
        if (response.data.success) {
          setDrivers(response.data.drivers);
        }
      } catch (error) {
        console.error("Error fetching drivers:", error.message);
        toast.error("Failed to fetch drivers");
      }
    };
    fetchDrivers();
  }, []);

  // Generic change handler using dot notation
  const handleInputChange = (e) => {
    const { name, value, type } = e.target;
    const updatePath = name.split(".");
    let updatedValue = value;
    if (type === "number") {
      updatedValue = parseFloat(value);
      if (isNaN(updatedValue)) updatedValue = 0;
    }
    setFormData((prevState) => {
      const newState = { ...prevState };
      let current = newState;
      updatePath.forEach((key, index) => {
        if (index === updatePath.length - 1) {
          current[key] = updatedValue;
        } else {
          current = current[key];
        }
      });
      return newState;
    });
  };

  // Trust selection sets the lot number automatically
  const handleTrustSelect = (selectedTrust) => {
    const trust = trustData.find((t) => t.name === selectedTrust);
    if (trust) {
      setFormData((prevState) => ({
        ...prevState,
        jobDetails: {
          ...prevState.jobDetails,
          trust: selectedTrust,
          lot: trust.lot.toString(),
        },
      }));
    }
  };

  const handleCostCenterSelect = (selectedCostCenter) => {
    setFormData((prevState) => ({
      ...prevState,
      jobDetails: { ...prevState.jobDetails, costCenter: selectedCostCenter },
    }));
  };

  // Handlers for staff, job cards, copy/paste, etc.
  const addStaffMember = () => {
    setFormData((prevState) => ({
      ...prevState,
      staffDetails: {
        ...prevState.staffDetails,
        staffList: [
          ...prevState.staffDetails.staffList,
          {
            id: prevState.staffDetails.staffList.length + 1,
            name: "",
            jobType: "",
          },
        ],
      },
    }));
  };

  const handleStaffNameChange = (staffId, newName) => {
    setFormData((prevState) => ({
      ...prevState,
      staffDetails: {
        ...prevState.staffDetails,
        staffList: prevState.staffDetails.staffList.map((staff) =>
          staff.id === staffId ? { ...staff, name: newName } : staff
        ),
      },
    }));
  };

  const handleJobDataChange = (jobId, field, value) => {
    setFormData((prevState) => ({
      ...prevState,
      jobs: {
        ...prevState.jobs,
        jobList: prevState.jobs.jobList.map((job) =>
          job.id === jobId ? { ...job, [field]: value } : job
        ),
      },
    }));
  };

  const addJob = () => {
    const newJobId = formData.jobs.jobList.length + 1;
    setFormData((prevState) => ({
      ...prevState,
      jobs: {
        ...prevState.jobs,
        jobList: [
          ...prevState.jobs.jobList,
          { id: newJobId, startingAddress: "", endingAddress: "", miles: "" },
        ],
      },
    }));
  };

  const removeJob = (jobId) => {
    setFormData((prevState) => ({
      ...prevState,
      jobs: {
        ...prevState.jobs,
        jobList: prevState.jobs.jobList.filter((job) => job.id !== jobId),
      },
    }));
  };

  const handleJobTypeChange = (staffId, jobType) => {
    setFormData((prevState) => ({
      ...prevState,
      staffDetails: {
        ...prevState.staffDetails,
        staffList: prevState.staffDetails.staffList.map((staff) =>
          staff.id === staffId ? { ...staff, jobType } : staff
        ),
      },
    }));
  };

  const removeStaffMember = (staffId) => {
    setFormData((prevState) => ({
      ...prevState,
      staffDetails: {
        ...prevState.staffDetails,
        staffList: prevState.staffDetails.staffList.filter(
          (staff) => staff.id !== staffId
        ),
      },
    }));
  };

  const handleAddReturnJob = (returnJob) => {
    setFormData((prevState) => ({
      ...prevState,
      jobs: {
        ...prevState.jobs,
        jobList: [...prevState.jobs.jobList, returnJob],
      },
    }));
  };

  const handleVehicleSelect = (selectedVehicle) => {
    setFormData((prevState) => ({
      ...prevState,
      jobDetails: {
        ...prevState.jobDetails,
        vehicle: `${selectedVehicle.username}: ${selectedVehicle.registrationNumber}`,
      },
    }));
  };

  const handleIsRenal = (selectedValue) => {
    // Convert the string "True" to boolean true; otherwise, false
    const isRenalBool = selectedValue === "True";
    setFormData((prevState) => ({
      ...prevState,
      jobDetails: {
        ...prevState.jobDetails,
        isRenal: isRenalBool,
      },
    }));
  };

  const getCostCenterLabel = (value) => {
    switch (value) {
      case "FCAE01":
        return "EAC";
      case "FCPE01":
        return "NEAC";
      case "Trust":
        return "Trust";
      default:
        return "N/A";
    }
  };

  const handleCopyJobs = () => {
    if (formData.jobs.jobList.length === 0) {
      toast.error("No jobs available to copy.");
      return;
    }
    localStorage.setItem("copiedJobs", JSON.stringify(formData.jobs.jobList));
    setCopiedJobs(formData.jobs.jobList);
    toast.success(`Copied ${formData.jobs.jobList.length} jobs!`);
  };

  const handlePasteJobs = () => {
    const storedJobs = localStorage.getItem("copiedJobs");
    if (!storedJobs) {
      toast.error("No copied jobs found.");
      return;
    }
    const parsedJobs = JSON.parse(storedJobs);
    const currentJobList = formData.jobs.jobList;
    const maxExistingId =
      currentJobList.length > 0
        ? Math.max(...currentJobList.map((job) => job.id))
        : 0;
    const updatedJobs = parsedJobs.map((job, index) => ({
      ...job,
      id: maxExistingId + index + 1,
    }));
    setFormData((prevState) => ({
      ...prevState,
      jobs: {
        ...prevState.jobs,
        jobList: [...prevState.jobs.jobList, ...updatedJobs],
      },
    }));
    toast.success(`Pasted ${updatedJobs.length} jobs!`);
  };

  return (
    <div className="px-10 my-8">
      <Dialog open={isDialogOpen} onOpenChange={handleCloseDialog}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Assign an ambulance</DialogTitle>
            <DialogClose />
          </DialogHeader>
          <DialogDescription>
            Assign an ambulance to this job.
          </DialogDescription>
          {drivers && (
            <Select
              value={selectedDriverUsername}
              onValueChange={(value) => setSelectedDriverUsername(value)}
            >
              <SelectTrigger>
                {selectedDriverUsername || "Select Vehicle"}
              </SelectTrigger>
              <SelectContent>
                {drivers.map((driver) => (
                  <SelectItem key={driver.username} value={driver.username}>
                    {driver.username}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          )}
          <Button
            variant="default"
            size="lg"
            className={buttonVariants({ variant: "default", size: "lg" })}
            onClick={handleAssignJob}
          >
            Assign Job
          </Button>
        </DialogContent>
      </Dialog>

      <div className="mb-4">
        <div className="flex flex-row items-center justify-between">
          <p className="text-lg font-semibold leading-none mb-2">Job Details</p>
          <div className="flex flex-row items-center gap-4">
            <Button
              variant="default"
              size="lg"
              className={`${buttonVariants({
                variant: "default",
                size: "lg",
              })} ml-2`}
              onClick={() => setIsDialogOpen(true)}
            >
              Assign Job
            </Button>
            <Button onClick={handleDeleteShiftJob}>Delete Job</Button>
            <Button onClick={handleUpdateShiftJob}>Update Job</Button>
          </div>
        </div>
        <div className="grid w-full grid-cols-2 gap-4 mb-4">
          {authData.role === "1" && (
            <div className="flex flex-col gap-1.5">
              <Label htmlFor="price">Starting Price</Label>
              <Input
                type="text"
                id="price"
                name="jobDetails.price"
                value={formData.jobDetails.price}
                onChange={handleInputChange}
              />
            </div>
          )}
          <div className="flex flex-col gap-1.5">
            <Label htmlFor="trust">Trust</Label>
            <Select
              value={formData.jobDetails.trust}
              onValueChange={handleTrustSelect}
            >
              <SelectTrigger>
                {formData.jobDetails.trust || "Select Trust"}
              </SelectTrigger>
              <SelectContent>
                {trustData.map((t) => (
                  <SelectItem key={t.name} value={t.name}>
                    {t.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
          <div className="flex flex-col gap-1.5">
            <Label htmlFor="costCenter">Cost Center</Label>
            <Select
              value={formData.jobDetails.costCenter}
              onValueChange={(value) => handleCostCenterSelect(value)}
            >
              <SelectTrigger>
                {formData.jobDetails.costCenter
                  ? getCostCenterLabel(formData.jobDetails.costCenter)
                  : "Select Cost Center"}
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="FCAE01">EAC</SelectItem>
                <SelectItem value="FCPE01">NEAC</SelectItem>
                <SelectItem value="Trust">Trust</SelectItem>
              </SelectContent>
            </Select>
          </div>
          <div className="flex flex-col gap-1.5">
            <Label htmlFor="jobStartDate">Job Start Date</Label>
            <Input
              type="date"
              id="jobStartDate"
              name="jobDetails.jobStartDate"
              value={formData.jobDetails.jobStartDate}
              onChange={handleInputChange}
            />
          </div>
          <div className="flex flex-col gap-1.5">
            <Label htmlFor="vehicle">Vehicle</Label>
            <Select
              value={formData.jobDetails.vehicle}
              onValueChange={handleVehicleSelect}
            >
              <SelectTrigger>
                {formData.jobDetails.vehicle || "Select Vehicle"}
              </SelectTrigger>
              <SelectContent>
                {drivers &&
                  drivers.map((driver) => (
                    <SelectItem key={driver.username} value={driver}>
                      {driver.username}
                    </SelectItem>
                  ))}
              </SelectContent>
            </Select>
          </div>
          <div className="flex flex-col gap-1.5">
            <Label htmlFor="callSign">Call Sign</Label>
            <Input
              type="text"
              id="callSign"
              name="jobDetails.callSign"
              value={formData.jobDetails.callSign}
              onChange={handleInputChange}
            />
          </div>
          <div className="flex flex-col gap-1.5">
            <Label htmlFor="lot">Lot Number</Label>
            <Input
              type="text"
              id="lot"
              name="jobDetails.lot"
              value={formData.jobDetails.lot}
              disabled
            />
          </div>
          <div className="flex flex-col gap-1.5">
            <Label htmlFor="isRenal">isRenal</Label>
            <Select
              value={formData.jobDetails.isRenal ? "True" : "False"}
              onValueChange={(value) => handleIsRenal(value)}
            >
              <SelectTrigger>
                {formData.jobDetails.isRenal !== undefined
                  ? formData.jobDetails.isRenal
                    ? "True"
                    : "False"
                  : "isRenal"}
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="True">True</SelectItem>
                <SelectItem value="False">False</SelectItem>
              </SelectContent>
            </Select>
          </div>
        </div>

        {/* Staff Details */}
        <div className="mt-4">
          <div className="flex flex-row items-center justify-between mb-4">
            <p className="text-lg font-semibold">Staff Details</p>
            <Button onClick={addStaffMember}>Add Staff</Button>
          </div>
          <div className="grid grid-cols-1 gap-4">
            {formData.staffDetails.staffList.map((staff) => (
              <div key={staff.id}>
                <StaffCard
                  staff={staff}
                  onStaffNameChange={handleStaffNameChange}
                  onRemoveStaff={() => removeStaffMember(staff.id)}
                  onJobTypeChange={(jobType) =>
                    handleJobTypeChange(staff.id, jobType)
                  }
                />
              </div>
            ))}
          </div>
        </div>

        {/* Shift Details */}
        <div className="mt-4">
          <p className="text-lg font-semibold mb-2">Shift Details</p>
          <div className="grid grid-cols-2 gap-4">
            <div className="flex flex-col gap-1.5">
              <Label htmlFor="logonTime">Log on time</Label>
              <Input
                type="time"
                id="logonTime"
                name="shiftDetails.logonTime"
                value={formData.shiftDetails.logonTime}
                onChange={handleInputChange}
              />
            </div>
            <div className="flex flex-col gap-1.5">
              <Label htmlFor="logoffTime">Log off time</Label>
              <Input
                type="time"
                id="logoffTime"
                name="shiftDetails.logoffTime"
                value={formData.shiftDetails.logoffTime}
                onChange={handleInputChange}
              />
            </div>
          </div>
        </div>

        {/* Job Details (Job Cards) */}
        <div className="mt-4">
          <div className="flex flex-row items-center justify-between mb-4">
            <p className="text-lg font-semibold">Job Details</p>
            <div className="items-center space-x-2">
              <Button onClick={handleCopyJobs}>Copy Jobs</Button>
              <Button onClick={handlePasteJobs}>Paste Jobs</Button>
              <Button onClick={addJob}>Add Job</Button>
            </div>
          </div>
          <div className="grid grid-cols-1 gap-4">
            {formData.jobs.jobList.map((job) => (
              <div key={job.id}>
                <JobCard
                  job={job}
                  onJobDataChange={handleJobDataChange}
                  onRemoveJob={() => removeJob(job.id)}
                  onAddReturnJob={handleAddReturnJob}
                />
              </div>
            ))}
          </div>
        </div>

        {/* Totals */}
        <div className="grid grid-cols-2 gap-4 mb-4">
          <div className="flex flex-col gap-1.5">
            <Label htmlFor="totalMiles">Total Miles</Label>
            <Input
              type="number"
              id="totalMiles"
              name="totalMiles"
              value={formData.jobs.totalMiles}
              onChange={handleInputChange}
              placeholder="1"
            />
          </div>
          <div className="flex flex-col gap-1.5">
            <Label htmlFor="totalJobs">Total Jobs</Label>
            <Input
              type="number"
              id="totalJobs"
              name="totalJobs"
              value={formData.jobs.totalJobs}
              onChange={handleInputChange}
              placeholder="1"
            />
          </div>
        </div>
        {formData.jobDetails.costCenter !== "Trust" && (
          <div className="mt-4">
            <p className="text-lg font-semibold mb-2">Breaks</p>
            <div className="flex items-center space-x-2">
              {formData.breakDetails.break2.visible ? (
                <Badge>2x 30 Min Breaks</Badge>
              ) : formData.breakDetails.break1.visible ? (
                <Badge>1x 30 Min Break</Badge>
              ) : (
                <p className="text-sm text-gray-500">No Breaks</p>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ViewNIASJob;
