import * as React from "react";
import moment from "moment";
import { format } from "date-fns";
import { Calendar as CalendarIcon } from "lucide-react";

import { cn } from "../../../Utils/utils";
import { Button } from "./Button";
import { Calendar } from "./Calender";
import { Popover, PopoverContent, PopoverTrigger } from "./Popover";

export function DatePicker({ selectedDate, onDateChange }) {
  // Convert selectedDate string to Date object
  const parsedDate = selectedDate
    ? moment(selectedDate, "YYYY-MM-DD", true).toDate()
    : null;

  // Ensure state updates correctly
  const [date, setDate] = React.useState(parsedDate);

  // Ensure that if selectedDate changes externally, the DatePicker reflects it
  React.useEffect(() => {
    if (selectedDate) {
      const newDate = moment(selectedDate, "YYYY-MM-DD", true).toDate();
      setDate(newDate);
    }
  }, [selectedDate]);

  console.log("Date Picker Selected Date:", selectedDate);
  console.log("Parsed Date (Moment.js to Date):", parsedDate);

  const handleDateChange = (selectedDate) => {
    if (selectedDate) {
      setDate(selectedDate);
      if (onDateChange) onDateChange(moment(selectedDate).format("YYYY-MM-DD")); // Format and pass to parent
    }
  };

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          className={cn(
            "w-[280px] justify-start text-left font-normal",
            !date && "text-muted-foreground"
          )}
        >
          <CalendarIcon className="mr-2 h-4 w-4" />
          {date ? format(date, "PPP") : <span>Pick a date</span>}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0">
        <Calendar
          mode="single"
          selected={date}
          onSelect={handleDateChange}
          initialFocus
        />
      </PopoverContent>
    </Popover>
  );
}