import { Label } from "@radix-ui/react-label";
import axios from "axios";
import moment from "moment";
import React, { useState } from "react";
import PlacesAutocomplete from "react-places-autocomplete";

import { Card, CardContent } from "../../Components/Global/UI/Card";
import { Dialog, DialogClose, DialogContent, DialogDescription, DialogHeader, DialogTitle, DialogTrigger } from "../../Components/Global/UI/Dialog";
import { BASE_URL } from "../../Screens/Settings/DASHBOARD_SETTINGS";
import { Button } from "../Global/UI/Button";
import { Input } from "../Global/UI/Input";

const JobCard = ({ job, onJobDataChange, onRemoveJob, onAddReturnJob }) => {
  const [distanceInMiles, setDistanceInMiles] = useState("");
  const [isLogOpen, setIsLogOpen] = useState(false);

  const calculateDistance = async (pickupAddress, dropOffAddress) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/api/settings/locations/calculateDistances`,
        {
          pickupAddress: pickupAddress,
          dropOffAddress: dropOffAddress,
        }
      );
      return response.data.distance;
    } catch (error) {
      console.error("Error calculating distance:", error);
      return "";
    }
  };

  const handleSelectStartingAddress = async (address) => {
    onJobDataChange(job.id, "startingAddress", address);
    if (job.endingAddress) {
      const miles = await calculateDistance(address, job.endingAddress);
      onJobDataChange(job.id, "miles", miles);
    }
  };

  const handleSelectEndingAddress = async (address) => {
    onJobDataChange(job.id, "endingAddress", address);
    if (job.startingAddress) {
      const miles = await calculateDistance(job.startingAddress, address);
      onJobDataChange(job.id, "miles", miles);
    }
  };

  const handleCreateReturn = async () => {
    const returnJob = {
      ...job,
      id: `${job.id + 1}`,
      startingAddress: job.endingAddress,
      endingAddress: job.startingAddress,
      miles: job.miles || "",
      referenceNumber: job.referenceNumber || "",
    };
    onAddReturnJob(returnJob);
  };

  return (
    <div className="border p-4 my-4 flex flex-col">
      <div className="flex flex-row justify-between w-full">
        <div></div>
        <div className="flex flex-row gap-4">
          <Dialog open={isLogOpen} onOpenChange={setIsLogOpen}>
            <DialogTrigger asChild>
              <Button>View Logs</Button>
            </DialogTrigger>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>Job Timestamps</DialogTitle>
                <DialogDescription>
                  Below are the timestamps recorded for this job.
                </DialogDescription>
              </DialogHeader>
              <div className="space-y-3 text-sm">
                <div>
                  <strong>At Starting Location:</strong>{" "}
                  {job.timestamps?.atStartingLocation
                    ? moment(job.timestamps.atStartingLocation).format(
                        "DD/MM/YYYY HH:mm:ss"
                      )
                    : "Not recorded"}
                </div>
                <div>
                  <strong>Left Starting Location:</strong>{" "}
                  {job.timestamps?.leftStartingLocation
                    ? moment(job.timestamps.leftStartingLocation).format(
                        "DD/MM/YYYY HH:mm:ss"
                      )
                    : "Not recorded"}
                </div>
                <div>
                  <strong>Arrived at Destination:</strong>{" "}
                  {job.timestamps?.arrivedDestination
                    ? moment(job.timestamps.arrivedDestination).format(
                        "DD/MM/YYYY HH:mm:ss"
                      )
                    : "Not recorded"}
                </div>
                <div>
                  <strong>Cleared:</strong>{" "}
                  {job.timestamps?.cleared
                    ? moment(job.timestamps.cleared).format(
                        "DD/MM/YYYY HH:mm:ss"
                      )
                    : "Not recorded"}
                </div>
              </div>
            </DialogContent>
          </Dialog>
          <Button onClick={handleCreateReturn}>Create Return</Button>
          <button onClick={() => onRemoveJob(job.id)}>X</button>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-4 items-center mb-4">
        <div className="flex flex-col gap-1.5">
          <Label htmlFor={`miles${job.id}`}>Patient Name</Label>
          <Input
            type="text"
            value={job.patientName}
            onChange={(e) =>
              onJobDataChange(job.id, "patientName", e.target.value)
            }
          />
        </div>
        <div className="flex flex-col gap-1.5">
          <Label htmlFor={`miles${job.id}`}>Patient DOB</Label>
          <Input
            id={`dob${job.id}`}
            type="date"
            value={
              job.patientDOB
                ? moment(job.patientDOB, "DD/MM/YYYY").format("YYYY-MM-DD")
                : ""
            } // Convert from API format to HTML input format
            onChange={(e) =>
              onJobDataChange(
                job.id,
                "patientDOB",
                moment(e.target.value, "YYYY-MM-DD").format("DD/MM/YYYY")
              )
            } // Convert back to API format on change
            placeholder="DD/MM/YYYY"
            className="w-full"
          />
        </div>
        <div className="grid w-full max-w-sm gap-1.5">
          <Label htmlFor={`startingAddress${job.id}`}>Starting Address</Label>
          <PlacesAutocomplete
           debounce={400}
            value={job.startingAddress}
            onChange={(address) =>
              onJobDataChange(job.id, "startingAddress", address)
            }
            onSelect={handleSelectStartingAddress}
            searchOptions={{
              componentRestrictions: { country: "gb" }, // Restrict to UK
            }}
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }) => (
              <div className="relative w-full">
                {/* Input Field */}
                <Input
                  {...getInputProps({
                    placeholder: "Type your pickup address...",
                    className:
                      "w-full p-3 border bg-white border-gray-300 rounded-md focus:ring-2 focus:ring-teal-400",
                  })}
                />

                {/* Suggestions Dropdown */}
                {suggestions.length > 0 && (
                  <Card className="absolute z-10 mt-2 w-full shadow-md border border-gray-200 rounded-md bg-white">
                    <CardContent className="p-0">
                      {loading && (
                        <p className="text-sm text-gray-500 px-3 py-2">
                          Loading...
                        </p>
                      )}

                      {suggestions.map((suggestion) => (
                        <button
                          key={suggestion.placeId}
                          {...getSuggestionItemProps(suggestion)}
                          className="w-full text-left hover:bg-gray-100 transition-colors"
                          style={{
                            padding: "0", // Remove internal padding
                            margin: "0", // Remove extra spacing
                            display: "block", // Ensures full-width clickable area
                          }}
                        >
                          <div className="px-3 py-2">
                            {suggestion.description}
                          </div>
                        </button>
                      ))}
                    </CardContent>
                  </Card>
                )}
              </div>
            )}
          </PlacesAutocomplete>
        </div>
        <div className="grid w-full max-w-sm gap-1.5">
          <Label htmlFor={`endingAddress${job.id}`}>Ending Address</Label>
          <PlacesAutocomplete
          debounce={400}
            value={job.endingAddress}
            onChange={(address) =>
              onJobDataChange(job.id, "endingAddress", address)
            }
            onSelect={handleSelectEndingAddress}
            searchOptions={{
              componentRestrictions: { country: "gb" }, // Restrict to UK
            }}
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }) => (
              <div className="relative w-full">
                {/* Input Field */}
                <Input
                  {...getInputProps({
                    placeholder: "Type your pickup address...",
                    className:
                      "w-full p-3 border bg-white border-gray-300 rounded-md focus:ring-2 focus:ring-teal-400",
                  })}
                />

                {/* Suggestions Dropdown */}
                {suggestions.length > 0 && (
                  <Card className="absolute z-10 mt-2 w-full shadow-md border border-gray-200 rounded-md bg-white">
                    <CardContent className="p-0">
                      {loading && (
                        <p className="text-sm text-gray-500 px-3 py-2">
                          Loading...
                        </p>
                      )}

                      {suggestions.map((suggestion) => (
                        <button
                          key={suggestion.placeId}
                          {...getSuggestionItemProps(suggestion)}
                          className="w-full text-left hover:bg-gray-100 transition-colors"
                          style={{
                            padding: "0", // Remove internal padding
                            margin: "0", // Remove extra spacing
                            display: "block", // Ensures full-width clickable area
                          }}
                        >
                          <div className="px-3 py-2">
                            {suggestion.description}
                          </div>
                        </button>
                      ))}
                    </CardContent>
                  </Card>
                )}
              </div>
            )}
          </PlacesAutocomplete>
        </div>
        <div className="flex flex-col gap-1.5">
          <Label htmlFor={`miles${job.id}`}>Miles</Label>
          <Input
            type="text"
            id={`miles${job.id}`}
            value={job.miles}
            onChange={(e) => onJobDataChange(job.id, "miles", e.target.value)}
          />
        </div>
        <div className="flex flex-col gap-1.5">
          <Label htmlFor={`referenceNumber${job.id}`}>Reference Number</Label>
          <Input
            type="text"
            id={`referenceNumber${job.id}`}
            value={job.referenceNumber}
            onChange={(e) =>
              onJobDataChange(job.id, "referenceNumber", e.target.value)
            }
          />
        </div>
        <div className="flex flex-col gap-1.5">
          <Label htmlFor={`notes${job.id}`}>Notes</Label>
          <Input
            type="text"
            id={`notes${job.id}`}
            value={job.notes}
            onChange={(e) => onJobDataChange(job.id, "notes", e.target.value)}
          />
        </div>
      </div>
    </div>
  );
};

export default JobCard;
