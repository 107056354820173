import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { toast } from "react-toastify";

import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../../Components/Global/UI/table";
import { useAuth } from "../../Context/AuthProvider";
import { BASE_URL } from "../../Screens/Settings/DASHBOARD_SETTINGS";
import renderStatusBadge from "../../Utils/renderStatusBadge";
import { Badge } from "../Global/UI/Badge";
import { Button, buttonVariants } from "../Global/UI/Button";
import { Checkbox } from "../Global/UI/checkbox";
import { DatePicker } from "../Global/UI/DatePicker";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "../Global/UI/Dialog";
import { Pagination } from "../Global/UI/Pagination";

const NiasJobList = () => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const { authData } = useAuth();
  const isAdmin = authData.role.toString() === "1";
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedItems, setSelectedItems] = useState([]);
  const [referenceCode, setReferenceCode] = useState("");

  // No default date: initialize dateRange with nulls
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const history = useHistory();

  // Build the fetchData function so that it conditionally includes date parameters.
  const fetchData = async (page = currentPage, startDate, endDate) => {
    setLoading(true);
    try {
      const params = {
        page,
        limit: pageSize,
        referenceCode: referenceCode || undefined,
      };

      // Include date parameters only if a date is set
      if (startDate || dateRange.startDate) {
        params.startDate = startDate || dateRange.startDate;
        params.endDate = endDate || dateRange.endDate;
      }

      const response = await axios.get(`${BASE_URL}/api/nias/job/get-all`, {
        params,
      });
      if (response.data.success) {
        setItems(response.data.results);
        setTotalPages(response.data.pagination.totalPages);
      }
    } catch (error) {
      toast.error(
        error.response?.data?.message || "An unknown error has occurred"
      );
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  // On mount, load saved state from localStorage if it exists.
  useEffect(() => {
    const savedState = JSON.parse(localStorage.getItem("NIAS_TAB"));
    console.log("Loaded from localStorage:", savedState);
    if (savedState) {
      const { currentPage: savedPage, dateRange: savedDateRange } = savedState;
      setCurrentPage(savedPage || 1);
      if (savedDateRange && savedDateRange.startDate) {
        setDateRange(savedDateRange);
        console.log("Restoring saved date range:", savedDateRange);
        fetchData(savedPage, savedDateRange.startDate, savedDateRange.endDate);
      } else {
        fetchData(savedPage);
      }
    } else {
      fetchData();
    }
  }, []);

  // Save state changes (currentPage and dateRange) to localStorage.
  useEffect(() => {
    const stateToSave = { currentPage, dateRange };
    localStorage.setItem("NIAS_TAB", JSON.stringify(stateToSave));
  }, [currentPage, dateRange]);

  // When the user selects a date, update state and fetch data.
  const handleDateChange = (date) => {
    if (!date) return;
    const formattedDate = moment(date).format("YYYY-MM-DD");
    console.log("User selected date:", formattedDate);
    const newDateRange = { startDate: formattedDate, endDate: formattedDate };
    setDateRange(newDateRange);
    // Reset to page 1 when a date is selected
    setCurrentPage(1);
    fetchData(1, formattedDate, formattedDate);
  };

  // Handle reference code changes: reset page to 1 and fetch data.
  const handleReferenceCodeChange = (e) => {
    const value = e.target.value;
    setReferenceCode(value);
    setCurrentPage(1);
    fetchData(1, dateRange.startDate, dateRange.endDate);
  };

  // Handle pagination changes.
  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchData(page, dateRange.startDate, dateRange.endDate);
  };

  const handleSelectItem = (itemId) => {
    setSelectedItems((prevSelected) =>
      prevSelected.includes(itemId)
        ? prevSelected.filter((id) => id !== itemId)
        : [...prevSelected, itemId]
    );
  };

  const handleSelectAll = () => {
    const allIds = items.map((item) => item._id);
    setSelectedItems(selectedItems.length === items.length ? [] : allIds);
  };

  const handleRemoveJobs = async () => {
    try {
      const response = await axios.post(
        `${BASE_URL}/api/nias/job/delete-nias-jobs`,
        {
          jobIds: selectedItems,
        }
      );
      if (response.data.success) {
        toast.success(response.data.msg);
        setSelectedItems([]);
        fetchData();
        setIsDialogOpen(false);
      } else {
        toast.error(response.data.msg || "Failed to delete jobs.");
      }
    } catch (error) {
      toast.error(
        error.response?.data?.message || "An unknown error has occurred"
      );
      console.error(error);
    }
  };

  const handleViewClick = (item) => {
    history.push({
      pathname: "/view-nias-job",
      state: { item },
    });
  };

  const getCostCenterName = (costCenter) => {
    switch (costCenter) {
      case "FCAE01":
        return "EAC";
      case "FCPE01":
        return "NEAC";
      case "Trust":
        return "Trust";
      default:
        return "N/A";
    }
  };

  const openInPlanday = (item) => {
    console.log(item);
    const companyDomain = "jacemedical";
    const shiftId = item.planday.jobID || "";
    const startDate =
      item.shiftDetails?.startDate ||
      moment().startOf("week").format("YYYY-MM-DD");
    const endDate =
      item.shiftDetails?.endDate || moment().endOf("week").format("YYYY-MM-DD");
    const plandayUrl = `https://${companyDomain}.planday.com/schedule#/scheduling/5680/board/roles/shift/${shiftId}?endDate=${endDate}&startDate=${startDate}`;
    window.open(plandayUrl, "_blank");
  };

  // If referenceCode changes, we already handle it in the onChange handler.
  // Therefore, we don't add an extra effect here.

  const handleSetDateToToday = () => {
    handleDateChange(moment().toDate());
  };

  const handleSetDateToTomorrow = () => {
    handleDateChange(moment().add(1, "day").toDate());
  };

  return (
    <div className="w-full overflow-x-auto my-4">
      <div className="mb-6 flex justify-between items-center">
        <h2 className="text-lg font-bold">Actions</h2>
        <div className="flex flex-row gap-4 items-center">
          <div className="flex items-center gap-2">
            <input
              type="text"
              placeholder="Enter Reference Code"
              value={referenceCode}
              onChange={handleReferenceCodeChange}
              className="border border-gray-300 rounded-md p-2"
            />
          </div>
          {/* Optional: Remove Today/Tomorrow buttons if you don't want default dates */}
          <Button
            variant="default"
            onClick={handleSetDateToToday}
            className={buttonVariants({ variant: "default" })}
          >
            Today
          </Button>
          <Button
            variant="default"
            onClick={handleSetDateToTomorrow}
            className={buttonVariants({ variant: "default" })}
          >
            Tomorrow
          </Button>
          {selectedItems.length > 0 && (
            <Button
              variant="destructive"
              onClick={() => setIsDialogOpen(true)}
              className={buttonVariants({ variant: "destructive" })}
            >
              Remove Jobs
            </Button>
          )}
          {/* Pass selectedDate from dateRange.startDate (could be null) */}
          <DatePicker
            selectedDate={dateRange.startDate}
            onDateChange={handleDateChange}
          />
        </div>
      </div>
      {loading ? (
        <div className="grid grid-cols-1 md:grid-cols-[500px 1fr 500px 1fr] gap-4 mt-4">
          {[...Array(15)].map((_, index) => (
            <div
              key={index}
              className="overflow-hidden border border-gray-200 rounded-md animate-pulse"
            >
              <div className="bg-gray-200 h-8 w-full"></div>
              <div className="h-px bg-gray-300"></div>
              <div className="bg-gray-200 h-8 w-full"></div>
              <div className="h-px bg-gray-300"></div>
              <div className="bg-gray-200 h-8 w-full"></div>
            </div>
          ))}
        </div>
      ) : (
        <>
          <div className="w-full">
            <Table className="w-full border-collapse">
              <TableHeader>
                <TableRow>
                  <TableHead>
                    <Checkbox
                      checked={
                        selectedItems.length === items.length &&
                        items.length > 0
                      }
                      onCheckedChange={handleSelectAll}
                      aria-label="Select all"
                    />
                  </TableHead>
                  <TableHead>Reference</TableHead>
                  <TableHead>Start Date</TableHead>
                  <TableHead>Start Time</TableHead>
                  <TableHead>Trust</TableHead>
                  <TableHead>Call Center</TableHead>
                  <TableHead>Starting Location</TableHead>
                  <TableHead>Call Sign</TableHead>
                  <TableHead>Staff</TableHead>
                  <TableHead>Mileage</TableHead>
                  {isAdmin && <TableHead>Price</TableHead>}
                  <TableHead>Status</TableHead>
                  <TableHead>Actions</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {items.map((item, index) => {
                  let rowClass = "";
                  if (
                    item.vehicleChecks?.endOfShift?.timeOfSignoff &&
                    item.shiftDetails?.logoffTime &&
                    item.jobDetails?.jobStartDate
                  ) {
                    // Build scheduled logoff datetime from the job start date and shift logoff time
                    const jobDate = moment(item.jobDetails.jobStartDate).format(
                      "YYYY-MM-DD"
                    );
                    const scheduledLogoff = moment(
                      `${jobDate} ${item.shiftDetails.logoffTime}`,
                      "YYYY-MM-DD HH:mm"
                    );
                    // Parse actual signoff time (assumed format: YYYY-MM-DD HH:mm:ss)
                    const actualSignoff = moment(
                      item.vehicleChecks.endOfShift.timeOfSignoff,
                      "YYYY-MM-DD HH:mm:ss"
                    );
                    const diffMinutes = actualSignoff.diff(
                      scheduledLogoff,
                      "minutes"
                    );
                    console.log(
                      `Row ${index}: Difference in minutes = ${diffMinutes}`
                    );
                    if (diffMinutes > 15) {
                      rowClass = "bg-yellow-200 hover:bg-yellow-100"; // Use your preferred Tailwind yellow background class
                    }
                  }
                  return (
                    <TableRow key={index} className={rowClass}>
                      <TableCell>
                        <Checkbox
                          checked={selectedItems.includes(item._id)}
                          onCheckedChange={() => handleSelectItem(item._id)}
                          aria-label={`Select ${item.jobDetails.trust}`}
                        />
                      </TableCell>
                      <TableCell>
                        {item.jobDetails.referenceCode || "N/A"}
                      </TableCell>
                      <TableCell>
                        <span>
                          {new Date(
                            item.jobDetails.jobStartDate
                          ).toLocaleDateString("en-US", {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                          })}
                        </span>
                      </TableCell>
                      <TableCell>
                        {item.shiftDetails.logonTime || "N/A"}
                      </TableCell>
                      <TableCell>
                        <span>{item.jobDetails.trust || "N/A"}</span>
                      </TableCell>
                      <TableCell>
                        {getCostCenterName(item.jobDetails.costCenter) || "N/A"}
                      </TableCell>
                      <TableCell>
                        {item.jobs.jobList[0]
                          ? item.jobs.jobList[0].startingAddress
                          : "N/A"}
                      </TableCell>
                      <TableCell>{item.jobDetails.callSign || "N/A"}</TableCell>
                      <TableCell>
                        {item.staffDetails.staffList.map((staff, idx) => (
                          <div key={idx}>{staff.name}</div>
                        ))}
                      </TableCell>
                      <TableCell>
                        <span>
                          {item.jobs.totalMiles
                            ? parseFloat(item.jobs.totalMiles).toFixed(2)
                            : "N/A"}
                        </span>
                      </TableCell>
                      {isAdmin && (
                        <TableCell>{item.jobDetails.price}</TableCell>
                      )}
                      <TableCell>
                        {renderStatusBadge(item.JobStatus || 0)}
                      </TableCell>
                      <TableCell>
                        <div className="flex flex-row items-center gap-2">
                          <Button
                            variant="default"
                            size="default"
                            className={buttonVariants({
                              variant: "default",
                              size: "default",
                            })}
                            onClick={() => handleViewClick(item)}
                          >
                            View Job
                          </Button>
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>
          <div className="mt-6 flex justify-center">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          </div>
        </>
      )}
      <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Confirm Deletion</DialogTitle>
            <DialogDescription>
              Are you sure you want to delete the selected jobs? This action
              cannot be undone.
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button variant="outline" onClick={() => setIsDialogOpen(false)}>
              Cancel
            </Button>
            <Button variant="destructive" onClick={handleRemoveJobs}>
              Confirm
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default NiasJobList;
