import { ScrollArea } from "../Components/Global/UI/ScrollArea";
import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import { Button } from "../Components/Global/UI/Button";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../Components/Global/UI/Card";
import { Input } from "../Components/Global/UI/Input";
import { useMessaging } from "../Context/ChatProvider";
import { IoCheckmark, IoCheckmarkDone } from "react-icons/io5";
import Avatar from "react-avatar";
import axios from "axios";
import { BASE_URL } from "./Settings/DASHBOARD_SETTINGS";

const ChatScreen = () => {
  const {
    socket,
    messages,
    groups,
    fetchUserData,
    sendMessage,
    markAsRead,
    createGroup,
  } = useMessaging();
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [newMessage, setNewMessage] = useState("");
  const scrollViewRef = useRef(null);
  const userId = "67a9fcc752cd1fc948a571ab"; // JACEBASE

  // Modal state for creating new chat
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);

  // Helper function to transform usernames
  const transformName = (name) => {
    if (!name) return ""; // Return an empty string if name is undefined or null
    const regex = /^JA(T)?(\d+)$/;
    const match = name.match(regex);
    if (match) {
      return `J${match[2]}`;
    }
    return name;
  };

  // Fetch user data when socket is ready.
  useEffect(() => {
    if (socket) {
      fetchUserData(userId);
    }
  }, [socket]);

  // Smooth scroll to the newest message with a slight delay.
  useLayoutEffect(() => {
    if (scrollViewRef.current) {
      setTimeout(() => {
        scrollViewRef.current.scrollTo({
          top: scrollViewRef.current.scrollHeight,
          behavior: "smooth",
        });
      }, 100);
    }
  }, [messages]);

  // Mark messages as read for the selected group.
  useEffect(() => {
    if (!selectedGroup) return;
    const unreadMessages = messages.some(
      (msg) =>
        (msg.group === selectedGroup._id ||
          msg.group?._id === selectedGroup._id) &&
        !msg.readBy.includes(userId)
    );
    if (unreadMessages) {
      markAsRead(selectedGroup._id, userId);
    }
  }, [messages, selectedGroup]);

  // Send a message.
  const handleSendMessage = () => {
    if (!newMessage.trim() || !selectedGroup) return;
    sendMessage({
      senderId: userId,
      groupId: selectedGroup._id,
      message: newMessage,
    });
    setNewMessage("");
  };

  // Toggle selection for a user in the modal.
  const toggleUserSelection = (driverId) => {
    setSelectedUsers((prevSelected) =>
      prevSelected.includes(driverId)
        ? prevSelected.filter((id) => id !== driverId)
        : [...prevSelected, driverId]
    );
  };

  // Start a new chat / create a group.
  const startChat = async () => {
    const participants = [...selectedUsers, userId];
    const groupName =
      selectedUsers.length === 1 ? "One-on-One Chat" : "New Group";
    await createGroup({
      name: groupName,
      participantIds: participants,
    });
    setIsModalOpen(false);
    setSelectedUsers([]);
    fetchUserData(userId);
  };

  // When the modal opens, fetch users.
  useEffect(() => {
    if (isModalOpen) {
      axios
        .get(`${BASE_URL}/api/drivers/getDrivers`)
        .then((response) => {
          if (response.data.success) {
            setUsers(response.data.drivers);
          } else {
            console.error("Failed to fetch drivers:", response.data.message);
          }
        })
        .catch((error) => {
          console.error("Error fetching drivers:", error);
        });
    }
  }, [isModalOpen]);

  return (
    <div className="flex">
      {/* Sidebar - Groups Section */}
      <div className="w-1/4 p-4">
        <Card>
          <CardHeader>
            <div className="flex items-center justify-between">
              <CardTitle>Chats</CardTitle>
              <Button onClick={() => setIsModalOpen(true)}>New Chat</Button>
            </div>
          </CardHeader>
          <CardContent className="space-y-2">
            {groups.map((group) => {
              // Default title is group.name.
              let chatTitle = group.name;
              if (group.participants && group.participants.length === 2) {
                const otherParticipant = group.participants.find(
                  (p) => p._id !== userId
                );
                if (otherParticipant) {
                  chatTitle = otherParticipant.username;
                }
              }
              // Compute unread count based on messages that do not include the current user's ID.
             
              return (
                <div
                  key={group._id}
                  onClick={() => setSelectedGroup(group)}
                  className={`flex items-center space-x-2 p-2 rounded cursor-pointer hover:bg-gray-200 ${
                    selectedGroup?._id === group._id ? "bg-gray-300" : ""
                  }`}
                >
                  <Avatar
                    name={transformName(chatTitle)}
                    size="40"
                    round={true}
                  />
                  <span className="font-medium">{chatTitle}</span>
                 
                </div>
              );
            })}
          </CardContent>
        </Card>
      </div>

      {/* Chat Area */}
      <div className="flex flex-col flex-1 p-4">
        {selectedGroup ? (
          <Card className="flex flex-col" style={{ height: "80vh" }}>
            <CardHeader>
              <CardTitle>
                {selectedGroup.participants &&
                selectedGroup.participants.length === 2
                  ? selectedGroup.participants.find((p) => p._id !== userId)
                      ?.username || selectedGroup.name
                  : selectedGroup.name}
              </CardTitle>
            </CardHeader>
            <ScrollArea
              ref={scrollViewRef}
              className="flex-1 px-4 overflow-y-auto border-b border-gray-200"
            >
              {messages
                .filter(
                  (msg) =>
                    msg.group === selectedGroup._id ||
                    msg.group?._id === selectedGroup._id
                )
                .map((msg, index) => {
                  const isSentByMe = msg.sender?._id === userId;
                  const senderName = msg.sender?.username || "Unknown";
                  const otherParticipants = selectedGroup.participants.filter(
                    (p) => p._id !== userId
                  );
                  const isRead =
                    msg.readBy?.length &&
                    otherParticipants.every((p) => msg.readBy.includes(p._id));
                  return (
                    <div
                      key={index}
                      className={`mb-3 flex flex-col ${
                        isSentByMe ? "items-end" : "items-start"
                      }`}
                    >
                      <p className="text-sm font-semibold text-gray-700">
                        {senderName}
                      </p>
                      <div
                        className={`p-3 rounded-lg max-w-xs ${
                          isSentByMe ? "bg-blue-500 text-white" : "bg-gray-200"
                        }`}
                      >
                        <p className="text-sm">{msg.message}</p>
                        {isSentByMe && (
                          <div className="flex items-center mt-1 text-xs">
                            {isRead ? (
                              <IoCheckmarkDone className="text-white" />
                            ) : (
                              <IoCheckmark className="text-white" />
                            )}
                            <span className="ml-1 text-white">
                              {isRead ? "Read" : "Sent"}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
            </ScrollArea>
            <CardContent className="p-3 my-3 flex">
              <Input
                type="text"
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                placeholder="Type a message..."
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    handleSendMessage();
                  }
                }}
                className="flex-1"
              />
              <Button onClick={handleSendMessage}>Send</Button>
            </CardContent>
          </Card>
        ) : (
          <div className="flex items-center justify-center h-full">
            <p className="text-gray-500">Select a chat to start messaging</p>
          </div>
        )}
      </div>

      {/* New Chat Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
          {/* Backdrop */}
          <div
            className="absolute inset-0 bg-black opacity-50"
            onClick={() => setIsModalOpen(false)}
          ></div>
          {/* Modal Container */}
          <div className="bg-white rounded-lg z-10 p-4 w-1/3 max-h-[60vh] flex flex-col">
            <div className="flex items-center justify-between mb-4">
              <h2 className="text-lg font-semibold">Select Participants</h2>
              <button onClick={() => setIsModalOpen(false)} className="text-xl">
                &times;
              </button>
            </div>
            {/* Scrollable User List */}
            <div
              className="flex-1 overflow-y-auto"
              style={{
                msOverflowStyle: "none",
                scrollbarWidth: "none",
              }}
            >
              <style jsx>{`
                div::-webkit-scrollbar {
                  display: none;
                }
              `}</style>
              {users.length > 0 ? (
                users.map((user) => {
                  const isSelected = selectedUsers.includes(user.driverId);
                  return (
                    <div
                      key={user.driverId}
                      className={`flex items-center p-2 cursor-pointer ${
                        isSelected ? "bg-blue-100" : "bg-white"
                      }`}
                      onClick={() => toggleUserSelection(user.driverId)}
                    >
                      <Avatar
                        name={transformName(user.username)}
                        size="48"
                        round={true}
                        className="mr-3"
                      />
                      <span className="font-semibold">{user.username}</span>
                    </div>
                  );
                })
              ) : (
                <p className="text-center text-gray-500">No users available.</p>
              )}
            </div>
            {/* Footer: Create Chat Button - Always Visible */}
            <div className="mt-4">
              <Button
                onClick={startChat}
                disabled={selectedUsers.length === 0}
                className="w-full"
              >
                {selectedUsers.length === 1 ? "Start Chat" : "Create Group"}
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatScreen;
