import { BellIcon, DrawingPinIcon, PersonIcon, StopwatchIcon } from "@radix-ui/react-icons";
import React from 'react';
import { useHistory } from 'react-router-dom';

import { Dialog, DialogClose, DialogContent, DialogDescription, DialogHeader, DialogTitle } from '../../../Components/Global/UI/Dialog';

const JobTypeDialog = ({ isOpen, onClose }) => {

  const history = useHistory();
  const handleJobTypeSelect = (jobType) => {
    onClose();
    handleAddJobClick(jobType);
  };

  const handleAddJobClick = (jobType) => {
    switch (jobType) {
      case 0:
        history.push('/add-journey-job');
        break;
      case 1:
        history.push('/add-shift-job');
        break;
      case 2:
        history.push('/add-repatriation-job');
        break;
      case 3:
        history.push('/add-nias-renal-job');
        break;
      case 4:
        history.push('/add-events-job');
        break;
      default:
        break;
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Select a Job Type</DialogTitle>
          <DialogClose />
        </DialogHeader>
        <DialogDescription>
          Please select the job type that you would like to create a request for.
        </DialogDescription>
        <div className="grid grid-cols-2 gap-4">
          <div className="flex items-center space-x-4 rounded-md border p-4 cursor-pointer" onClick={() => handleJobTypeSelect(0)}>
            <BellIcon />
            <div className="flex-1 space-y-1">
              <p className="text-sm font-semibold leading-none">Journey Request</p>
              <p className="text-sm text-muted-foreground">Create a new Journey job request.</p>
            </div>
          </div>
          <div className="flex items-center space-x-4 rounded-md border p-4 cursor-pointer" onClick={() => handleJobTypeSelect(1)}>
            <PersonIcon />
            <div className="flex-1 space-y-1">
              <p className="text-sm font-semibold leading-none">Shift Request</p>
              <p className="text-sm text-muted-foreground">Create a new Shift job request.</p>
            </div>
          </div>
          <div className="flex items-center space-x-4 rounded-md border p-4 cursor-pointer" onClick={() => handleJobTypeSelect(2)}>
            <StopwatchIcon />
            <div className="flex-1 space-y-1">
              <p className="text-sm font-semibold leading-none">Repatriation</p>
              <p className="text-sm text-muted-foreground">Create a new repatriation job request.</p>
            </div>
          </div>
          <div className="flex items-center space-x-4 rounded-md border p-4 cursor-pointer" onClick={() => handleJobTypeSelect(4)}>
            <DrawingPinIcon />
            <div className="flex-1 space-y-1">
              <p className="text-sm font-semibold leading-none">{`Events`}</p>
              <p className="text-sm text-muted-foreground">Create a new Events job request.</p>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default JobTypeDialog;
