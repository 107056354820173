import React, { createContext, useContext, useEffect, useState } from "react";
import { io } from "socket.io-client";
import { WS_URL } from "../Screens/Settings/DASHBOARD_SETTINGS";


const MessagingContext = createContext();

export const MessagingProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);
  const [messages, setMessages] = useState([]);
  const [groups, setGroups] = useState([]);
  const [currentChat, setCurrentChat] = useState(null);
  const [onlineUsers, setOnlineUsers] = useState(new Set());

  useEffect(() => {
    console.log("Initializing socket connection...");
    const socketInstance = io(WS_URL, {
      transports: ["polling", "websocket"],
    });

    socketInstance.on("connect", () => {
      console.log("Connected to server:", socketInstance.id);
    });

    socketInstance.on("connect_error", (error) => {
      console.error("Connection error:", error);
    });

    socketInstance.on("disconnect", (reason) => {
      console.log("Disconnected:", reason);
    });

    setSocket(socketInstance);

    return () => {
      console.log("Disconnecting socket...");
      socketInstance.disconnect();
    };
  }, []);

  useEffect(() => {
    if (!socket) return;
  
    console.log("Setting up newMessage listener...");
    const handleNewMessage = (data) => {
      console.log("Received newMessage:", data);
  
      if (data.success) {
        const message = data.message;
  
        // Add the new message to `messages` if it's not already present
        setMessages((prevMessages) => {
          if (prevMessages.some((msg) => msg._id === message._id)) {
            return prevMessages; // Avoid duplicates
          }
          return [...prevMessages, message];
        });
  
        // Update the relevant group with the new message
        setGroups((prevGroups) =>
          prevGroups.map((group) => {
            const groupId = typeof message.group === "string" ? message.group : message.group._id;
            if (group._id === groupId) {
              return {
                ...group,
                messages: [...group.messages, message], // Append the message to the group
              };
            }
            return group;
          })
        );
      }
    };
  
    socket.on("newMessage", handleNewMessage);
  
    return () => {
      socket.off("newMessage", handleNewMessage);
    };
  }, [socket]);

  useEffect(() => {
    if (!socket) return;

    console.log("Setting up online/offline listeners...");

    socket.on("userOnline", ({ userId }) => {
      setOnlineUsers((prev) => {
        const updatedUsers = new Set([...prev, userId]);
        console.log("User came online:", userId, " | Online Users:", [...updatedUsers]);
        return updatedUsers;
      });
    });

    socket.on("userOffline", ({ userId }) => {
      setOnlineUsers((prev) => {
        const updatedUsers = new Set(prev);
        updatedUsers.delete(userId);
        console.log("User went offline:", userId, " | Online Users:", [...updatedUsers]);
        return updatedUsers;
      });
    });

    return () => {
      socket.off("userOnline");
      socket.off("userOffline");
    };
  }, [socket]);

  const fetchUserData = (driverId) => {
    if (!socket) return;
  
    console.log('Fetching user data...');
    socket.emit('getUserData', driverId, (response) => {
      if (response.success) {
        const allGroupMessages = response.groups.flatMap((group) => group.messages || []);
        setGroups(response.groups);
        setMessages(allGroupMessages); // Merge all group messages
        setOnlineUsers(new Set(response.onlineUsers || []));
        // Join all the user's group rooms
        response.groups.forEach((group) => {
          console.log(`Joining room: ${group._id}`);
          socket.emit('joinGroupRoom', group._id); // Emit a "joinGroupRoom" event to the server
        });
  
        socket.emit("userOnline", driverId);
        console.log('User data fetched successfully:', response.groups);
      } else {
        console.error('Failed to fetch user data:', response.message);
      }
    });
  };

  const sendMessage = (data) => {
    if (!socket) return;

    console.log("Sending message...");
    socket.emit("sendMessage", data, (response) => {
      if (!response.success) {
        console.error("Failed to send message:", response.message);
      }
    });
  };

  useEffect(() => {
    if (!socket) return;
  
    console.log("Setting up messageRead listener...");
    
    socket.on("messageRead", ({ groupId, updatedMessages }) => {
      console.log(`Messages in group ${groupId} marked as read`, updatedMessages);
  
      // Force state update immediately to show read receipts
      setMessages((prevMessages) => {
        return prevMessages.map((msg) => {
          const updatedMessage = updatedMessages.find((updated) => updated._id === msg._id);
          return updatedMessage ? { ...msg, readBy: updatedMessage.readBy } : msg;
        });
      });
    });
  
    return () => {
      socket.off("messageRead");
    };
  }, [socket]);

  const markAsRead = (groupId, userId) => {
    if (!socket || !groupId || !userId) return;

    console.log(`Marking messages as read in group: ${groupId} for user: ${userId}`);
    socket.emit("markAsRead", { groupId, userId });

    // Update local state to reflect read messages
    setMessages((prevMessages) =>
      prevMessages.map((msg) =>
        msg.group._id === groupId && !msg.readBy?.includes(userId)
          ? { ...msg, readBy: [...(msg.readBy || []), userId] }
          : msg
      )
    );
  };

  const createGroup = (groupData) => {
    if (!socket) return;

    console.log("Creating group...");
    socket.emit("createGroup", groupData, (response) => {
      if (response.success) {
        setGroups((prevGroups) => [...prevGroups, response.group]);
        console.log("Group created successfully:", response.group);
      } else {
        console.error("Failed to create group:", response.message);
      }
    });
  };

  // Delete or leave a chat/group
  const deleteChat = (groupId, userId) => {
    if (!socket) return;
    console.log("Emitting deleteChat for group:", groupId);
    socket.emit("deleteChat", { groupId, userId }, (response) => {
      if (response.success) {
        console.log("Chat deleted/left successfully:", response.message);
        // Optionally, refresh user data so that groups/messages state gets updated:
        fetchUserData(userId);
      } else {
        console.error("Error deleting chat:", response.message);
      }
    });
  };

  const updateGroupName = (groupId, newName,userId, callback) => {
    if (!socket) return;
    console.log("Emitting updateGroupName for group:", groupId);
    socket.emit("updateGroupName", { groupId, newName }, (response) => {
      if (response.success) {
        console.log("Group name updated successfully:", response.group);
        // Optionally refresh the chats after renaming:
        fetchUserData(userId);
        if (callback) callback(response.group);
      } else {
        console.error("Error updating group name:", response.message);
        if (callback) callback(null, response.message);
      }
    });
  };

  const addReaction = (messageId, reactionType, userId, callback) => {
    if (!socket) return;
    console.log("Emitting addReaction for message:", messageId);
    socket.emit("addReaction", { messageId, reactionType, userId }, (response) => {
      if (response.success) {
        console.log("Reaction added successfully:", response.message);
        if (callback) callback(response.message);
      } else {
        console.error("Error adding reaction:", response.message);
        if (callback) callback(null, response.message);
      }
    });
  };

  // Remove a reaction from a message
  const removeReaction = (messageId, reactionType, userId, callback) => {
    if (!socket) return;
    console.log("Emitting removeReaction for message:", messageId);
    socket.emit("removeReaction", { messageId, reactionType, userId }, (response) => {
      if (response.success) {
        console.log("Reaction removed successfully:", response.message);
        if (callback) callback(response.message);
      } else {
        console.error("Error removing reaction:", response.message);
        if (callback) callback(null, response.message);
      }
    });
  };

  return (
    <MessagingContext.Provider
      value={{
        socket,
        messages,
        groups,
        currentChat,
        setCurrentChat,
        fetchUserData,
        sendMessage,
        createGroup,
        onlineUsers,
        markAsRead,
        deleteChat,
        updateGroupName,
        addReaction,
        removeReaction
      }}
    >
      {children}
    </MessagingContext.Provider>
  );
};

export const useMessaging = () => useContext(MessagingContext);