import React from 'react';
import { Label } from "../../../Global/UI/Label";
import { Input } from '../../../Global/UI/Input';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";

const ViewEventDetails = ({ onChange , data }) => {
    const [eventDetails, setEventDetails] = React.useState({
        eventLocation: data.eventLocation ||  '',
        eventDates: data.eventDates || '',
        eventStartingTime: data.eventStartingTime || '',
        eventEndingTime: data.eventEndingTime || '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        const updatedDetails = {
            ...eventDetails,
            [name]: value,
        };

        setEventDetails(updatedDetails);
        onChange(updatedDetails);
    };

    const handleLocationChange = (address) => {
        const updatedDetails = {
            ...eventDetails,
            eventLocation: address,
        };

        setEventDetails(updatedDetails);
        onChange(updatedDetails);
    };

    const handleSelectLocation = async (address) => {
        const results = await geocodeByAddress(address);
        const updatedDetails = {
            ...eventDetails,
            eventLocation: address,
        };

        setEventDetails(updatedDetails);
        onChange(updatedDetails);
    };


    return (
        <div>
            <p className='font-bold text-2xl mb-4'>Event Details</p>
            <div className='grid gap-4 grid-cols-1 md:grid-cols-3 mb-4'>
                <div>
                    <Label htmlFor="eventLocation" className="block font-bold mb-2">Event Location:</Label>
                    <PlacesAutocomplete
                     debounce={400}
                        value={eventDetails.eventLocation}
                        onChange={handleLocationChange}
                        onSelect={handleSelectLocation}
                    >
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                            <div>
                                <Input
                                    {...getInputProps({
                                        placeholder: 'Type your event location...',
                                        className: 'w-full p-2 border bg-white border-gray-300 rounded focus:ring-2 focus:ring-inset focus:ring-teal-400',
                                    })}
                                />
                                <div>
                                    {loading && <div>Loading...</div>}
                                    {suggestions.map(suggestion => {
                                        const style = {
                                            backgroundColor: suggestion.active ? '#41b6e6' : '#fff',
                                        };
                                        return (
                                            <div {...getSuggestionItemProps(suggestion, { style })} key={suggestion.placeId}>
                                                {suggestion.description}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                    </PlacesAutocomplete>
                </div>
                <div>
                    <Label htmlFor="eventDates" className="block font-bold mb-2">Event Dates:</Label>
                    <Input
                        value={eventDetails.eventDates}
                        onChange={handleChange}
                        type="date"
                        id="eventDates"
                        name="eventDates"
                        className="w-full p-2 border border-gray-300 rounded focus:ring-2 focus:ring-inset focus:ring-teal-400"
                    />
                </div>
                <div>
                    <Label htmlFor="eventStartingTime" className="block font-bold mb-2">Event Starting Time:</Label>
                    <Input
                        value={eventDetails.eventStartingTime}
                        onChange={handleChange}
                        type="time"
                        id="eventStartingTime"
                        name="eventStartingTime"
                        className="w-full p-2 border border-gray-300 rounded focus:ring-2 focus:ring-inset focus:ring-teal-400"
                    />
                </div>
                <div>
                    <Label htmlFor="eventEndingTime" className="block font-bold mb-2">Event Ending Time:</Label>
                    <Input
                        value={eventDetails.eventEndingTime}
                        onChange={handleChange}
                        type="time"
                        id="eventEndingTime"
                        name="eventEndingTime"
                        className="w-full p-2 border border-gray-300 rounded focus:ring-2 focus:ring-inset focus:ring-teal-400"
                    />
                </div>
            </div>
        </div>
    );
};

export default ViewEventDetails;
